<template>
    <div class="the-navbar__user-meta flex items-center">
        <div class="text-right leading-tight">
            <p class="font-semibold">{{ displayName }}</p>
            <small>{{ displayStatus }}</small>
        </div>

        <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
            <div class="con-img ml-3">
                <vs-avatar
                    v-if="user || accessCode"
                    badge
                    :text="displayName"
                    color="lightgrey"
                    :src="image"
                />
                <vs-avatar v-else badge color="danger" />
            </div>

            <vs-dropdown-menu class="vx-navbar-dropdown">
                <ul style="min-width: 9rem">
                    <li
                        class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                        @click="logout"
                    >
                        <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
                        <span class="ml-2">Logout</span>
                    </li>
                </ul>
            </vs-dropdown-menu>
        </vs-dropdown>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {}
    },
    computed: {
        user() {
            return this.$store.getters.user
        },
        accessCode() {
            return this.$store.getters.accessCode
        },
        image() {
            if (this.user && this.user.broker_permission)
                return this.user.broker_permission.logo
            if (this.accessCode) {
                return this.accessCode.broker.logo
            }
            return null
        },
        displayName() {
            if (this.user) {
                if (this.user.first_name && this.user.last_name)
                    return `${this.user.first_name} ${this.user.last_name}`
                return this.user.email
            } else if (this.accessCode) {
                return this.accessCode.broker.company_name
            }
            return 'Anonymous User'
        },
        displayStatus() {
            if (
                this.user &&
                this.user.broker_permission &&
                this.user.broker_permission.company_name
            )
                return this.user.broker_permission.company_name
            else if (this.accessCode) return this.accessCode.description
            return 'Offline'
        },
    },
    methods: {
        logout() {
            this.$store.dispatch('logout')
        },
    },
}
</script>
