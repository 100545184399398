<template>
    <div class="vx-auto-suggest">
        <div class="flex items-center relative">
            <!-- Input -->
            <vs-input
                ref="input"
                :placeholder="placeholder"
                :class="inputClassses"
                class="z-50"
                icon-pack="feather"
                icon="icon-search"
                icon-no-border
                v-model="searchQuery"
                @keyup.esc="escPressed"
                @keyup.up="increaseIndex(false)"
                @keyup.down="increaseIndex"
                @keyup.enter="suggestionSelected"
                @focus="updateInputFocus"
                @paste="updateInputFocus"
                @blur="updateInputFocus(false)"
            />
        </div>

        <!-- Group List -->

        <ul
            ref="scrollContainer"
            :class="{ hidden: !inputFocused }"
            class="auto-suggest-suggestions-list z-50 rounded-lg mt-2 shadow-lg overflow-x-hidden"
            @mouseenter="insideSuggestions = true"
            @mouseleave="insideSuggestions = false"
            @focus="updateInputFocus"
            @blur="updateInputFocus(false)"
            tabindex="-1"
        >
            <li
                ref="grp_list"
                v-for="(suggestion_list, grp_name, grp_index) in filteredData"
                :key="grp_index"
                class="auto-suggest__suggestion-group-container"
            >
                <!-- Group Header -->
                <p
                    class="auto-suggest__suggestion-group-title pt-3 pb-1 px-4"
                    v-if="!hideGroupTitle"
                >
                    <slot name="group" :group_name="grp_name"></slot>
                </p>

                <!-- Suggestion List of each group -->

                <ul>
                    <li
                        v-for="(suggestion, index) in suggestion_list"
                        :key="index"
                        class="auto-suggest__suggestion-group__suggestion py-3 px-4 cursor-pointer"
                        :class="{
                            'vx-auto-suggest__current-selected':
                                currentSelected == `${grp_index}.${index}`,
                        }"
                        @mouseenter="currentSelected = `${grp_index}.${index}`"
                        @click="suggestionSelected"
                    >
                        <div class="flex items-end leading-none py-1">
                            <feather-icon
                                :icon="suggestion.icon"
                                svgClasses="h-5 w-5"
                                class="mr-4"
                            />
                            <span class="mt-1">{{ suggestion.title }}</span>
                        </div>
                    </li>

                    <li
                        class="auto-suggest__suggestion-group__suggestion py-3 px-4 no-results"
                        v-if="!suggestion_list.length && searchQuery"
                    >
                        <slot name="noResult" :group_name="grp_name">
                            <p>No Results Found.</p>
                        </slot>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
// import { http } from '@/services'

export default {
    props: {
        placeholder: {
            type: String,
            default: 'Search..',
        },
        data: {
            type: Object,
            required: true,
        },
        initalData: {
            type: Object,
            default: () => new Object(),
        },
        inputClassses: {
            type: [String, Object, Array],
        },
        autoFocus: {
            type: Boolean,
            default: false,
        },
        showPinned: {
            type: Boolean,
            default: true,
        },
        searchLimit: {
            type: Number,
            default: 20,
        },
        hideGroupTitle: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            searchQuery: '',
            filteredData: {},
            currentSelected: -1,
            inputFocused: false,
            insideSuggestions: false,
        }
    },
    watch: {
        // UPDATE SUGGESTIONS LIST
        searchQuery: {
            deep: true,
            immediate: true,
            handler(value) {
                this.searchQueryHandler(value)
            },
        },
        autoFocus(val) {
            if (val) this.focusInput()
            else this.searchQuery = ''
        },
        filteredData(val) {
            // Auto Select first item if it's not item-404
            let grp_index = null

            for (let [index, grp_suggestions] of Object.values(val).entries()) {
                if (grp_suggestions.length) {
                    grp_index = index
                    break
                }
            }

            if (grp_index != null) this.currentSelected = grp_index + '.0'
        },
    },
    methods: {
        async searchQueryHandler(value) {
            if (value == '') {
                this.inputInit()
            }
            this.searchQuery = value
            if (value.length > 2) {
                // await this.searchForWills(value)
                // await this.searchForUsers(value)
                // await this.searchForAdvice(value)
                this.doSearchQuery(value)
            } else this.doSearchQuery(value)
        },
        escPressed() {
            this.$emit('closeSearchbar')
            this.searchQuery = ''
        },
        filter_grp(grp) {
            let exactEle = grp.data.filter((item) => {
                return item[grp.key]
                    .toLowerCase()
                    .startsWith(this.searchQuery.toLowerCase())
            })
            let containEle = grp.data.filter((item) => {
                return (
                    !item[grp.key]
                        .toLowerCase()
                        .startsWith(this.searchQuery.toLowerCase()) &&
                    item[grp.key]
                        .toLowerCase()
                        .indexOf(this.searchQuery.toLowerCase()) > -1
                )
            })
            return exactEle.concat(containEle).slice(0, this.searchLimit)
        },
        inputInit() {
            if (
                Object.entries(this.initalData).length === 0 &&
                this.initalData.constructor === Object
            ) {
                this.filteredData = {}
                console.log('1')
            } else {
                console.log('2')
                this.filteredData = this.initalData
            }
        },
        updateInputFocus(val = true) {
            if (val) {
                if (this.searchQuery == '') this.inputInit()
                setTimeout(() => {
                    this.inputFocused = true
                }, 100)
            } else {
                if (this.insideSuggestions) return
                setTimeout(() => {
                    this.inputFocused = false
                }, 100)
                this.escPressed()
            }
        },
        suggestionSelected() {
            if (this.currentSelected > -1) {
                const [grp_index, item_index] = this.currentSelected.split('.')

                const grp_of_selected_item = Object.keys(this.data)[grp_index]
                const selected_item = this.filteredData[grp_of_selected_item][
                    item_index
                ]

                this.$emit('selected', {
                    [grp_of_selected_item]: selected_item,
                })

                this.searchQuery = ''
            }
        },
        increaseIndex(val = true) {
            const [grp_i, item_i] = this.currentSelected.split('.')

            const grp_arr = Object.entries(this.filteredData)
            const active_grp_total_items = grp_arr[grp_i][1].length

            if (val) {
                // If active item is not of last item in grp
                if (active_grp_total_items - 1 > item_i) {
                    this.currentSelected = grp_i + '.' + (Number(item_i) + 1)

                    // If active item grp is not last in grp list
                } else if (grp_i < grp_arr.length - 1) {
                    this.currentSelected = Number(grp_i) + 1 + '.0'
                }
            } else {
                // If active item is not of first item in grp
                if (Number(item_i)) {
                    this.currentSelected = grp_i + '.' + (Number(item_i) - 1)

                    // If active item grp  is not first in grp list
                } else if (Number(grp_i)) {
                    this.currentSelected =
                        Number(grp_i) -
                        1 +
                        '.' +
                        (grp_arr[grp_i - 1][1].length - 1)
                }
            }
        },
        focusInput() {
            this.$refs.input.$el.querySelector('input').focus()
        },
        async searchForWills(value) {
            /*
        return http.get('wills_search_widget', {params: {query: value}}).then(
          response => {
            var willsData = response.data.map((value, index, array) => {
              return {
                title: `${value.user.details.full_name} ${value.user.email} ${value.user.details.address_line_1 || ''} ${value.user.details.address_line_2 || ''} ${value.user.details.post_town || ''} ${value.user.details.post_code || ''} ${value.user.details.phone}`,
                url: `/will/${value.id}`,
                icon: 'FileTextIcon',
                is_bookmarked: false
              }
            })
            var wills = {
              key: 'title',
              data: willsData
            }
            this.$store.commit('UPDATE_SEARCH_RESULTS', {value: 'wills', data: wills})
            return true
          }
        ).catch(
          error => {
            console.log(error)
            return false
          }
        )

           */
        },
        async searchForUsers(value) {
            /*
        return http.get('users_search_widget', {params: {query: value}}).then(
          response => {
            var usersData = response.data.map((value, index, array) => {
              return {
                title: `${value.details.full_name} ${value.email} ${value.details.address_line_1 || ''} ${value.details.post_town || ''} ${value.details.post_code || ''} ${value.details.phone || ''}`,
                url: `/user/${value.id}`,
                icon: 'UserIcon',
                is_bookmarked: false
              }
            })
            var users = {
              key: 'title',
              data: usersData
            }
            this.$store.commit('UPDATE_SEARCH_RESULTS', {value: 'users', data: users})
            return true
          }
        ).catch(
          error => {
            console.log(error)
            return false
          }
        )

           */
        },
        async searchForAdvice(value) {
            /*
        return http.get('advice_search_widget', {params: {query: value}}).then(
          response => {
            var adviceData = response.data.map((value, index, array) => {
              var label = ''
              var link = ''
              if (value.client_name) {
                label =`${value.client_name} - Email: ${value.client_email} - Phone: ${value.client_phone || ''}`
                link = `/advice/search/${value.client_email || value.client_name}`
              } else {
                label = `${value.user.details.full_name} - ${value.user.email} ${value.user.details.address_line_1 || ''} ${value.user.details.post_town || ''} ${value.user.details.post_code || ''} ${value.user.details.phone || ''}`
                link = `/user/${value.user.details.user_id}`
              }
              return {
                title: label,
                url: link,
                icon: 'UserIcon',
                is_bookmarked: false
              }
            })
            var advice = {
              key: 'title',
              data: adviceData
            }
            this.$store.commit('UPDATE_SEARCH_RESULTS', {value: 'advice', data: advice})
            return true
          }
        ).catch(
          error => {
            console.log(error)
            return false
          }
        )

           */
        },
        async doSearchQuery(val) {
            this.$emit('input', val)

            if (val == '') {
                this.inputInit()
            } else {
                let queried_data = {}
                const data_grps = Object.keys(this.data)

                data_grps.forEach((grp, i) => {
                    queried_data[data_grps[i]] = this.filter_grp(this.data[grp])
                })

                // Check if any of group has at least one queried item
                if (!Object.values(queried_data).some((obj) => obj.length)) {
                    this.currentSelected = -1
                }

                this.filteredData = queried_data
                console.log(queried_data)
            }
        },
    },
    mounted() {
        if (this.autoFocus) this.focusInput()
    },
}
</script>

<style lang="scss">
@import '@/assets/scss/vuexy/components/vxAutoSuggest.scss';
</style>
