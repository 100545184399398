<template>
    <!-- NOTIFICATIONS -->
    <vs-dropdown vs-custom-content class="cursor-pointer">
        <feather-icon
            icon="BellIcon"
            class="cursor-pointer mt-1 sm:mr-6 mr-2"
            :badge="notifications.length"
        />

        <vs-dropdown-menu
            class="notification-dropdown dropdown-custom vx-navbar-dropdown"
        >
            <div class="notification-top text-center p-5 bg-primary text-white">
                <h3 class="text-white">{{ notifications.length }} New</h3>
                <p class="opacity-75">App Notifications</p>
            </div>

            <VuePerfectScrollbar
                ref="mainSidebarPs"
                class="scroll-area--nofications-dropdown p-0 mb-10"
                :settings="settings"
                :key="$vs.rtl"
            >
                <ul class="bordered-items">
                    <li
                        v-for="(notification, index) in notifications"
                        :key="index"
                        class="flex justify-between px-4 py-4 notification cursor-pointer"
                        v-on:click="gotoPage(notification)"
                    >
                        <div class="flex items-start">
                            <feather-icon
                                :icon="notificationIcon(notification)"
                                :svgClasses="[
                                    `text-${notificationColour(notification)}`,
                                    'stroke-current mr-1 h-6 w-6',
                                ]"
                            ></feather-icon>
                            <div class="mx-2">
                                <span
                                    class="font-medium block notification-title"
                                    :class="[
                                        `text-${notificationColour(
                                            notification
                                        )}`,
                                    ]"
                                    >{{ title(notification) }}</span
                                >
                                <small>{{ subTitle(notification) }}</small>
                            </div>
                        </div>
                        <small class="mt-1 whitespace-no-wrap">{{
                            elapsedTime(notification.date)
                        }}</small>
                    </li>
                </ul>
            </VuePerfectScrollbar>

            <div
                class="checkout-footer fixed bottom-0 rounded-b-lg text-primary w-full p-2 font-semibold text-center border border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light cursor-pointer"
            >
                <span v-on:click="$store.commit('notificationsClear')"
                    >Clear All Notifications</span
                >
            </div>
        </vs-dropdown-menu>
    </vs-dropdown>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
    components: {
        VuePerfectScrollbar,
    },
    computed: {
        notifications() {
            return []
        },
    },
    data() {
        return {
            settings: {
                maxScrollbarLength: 60,
                wheelSpeed: 0.6,
            },
        }
    },
    methods: {
        elapsedTime(startTime) {
            let x = new Date(startTime)
            let now = new Date()
            var timeDiff = now - x
            timeDiff /= 1000

            var seconds = Math.round(timeDiff)
            timeDiff = Math.floor(timeDiff / 60)

            var minutes = Math.round(timeDiff % 60)
            timeDiff = Math.floor(timeDiff / 60)

            var hours = Math.round(timeDiff % 24)
            timeDiff = Math.floor(timeDiff / 24)

            var days = Math.round(timeDiff % 365)
            timeDiff = Math.floor(timeDiff / 365)

            var years = timeDiff

            if (years > 0) {
                return years + (years > 1 ? ' Years ' : ' Year ') + 'ago'
            } else if (days > 0) {
                return days + (days > 1 ? ' Days ' : ' Day ') + 'ago'
            } else if (hours > 0) {
                return hours + (hours > 1 ? ' Hrs ' : ' Hour ') + 'ago'
            } else if (minutes > 0) {
                return minutes + (minutes > 1 ? ' Mins ' : ' Min ') + 'ago'
            } else if (seconds > 0) {
                return seconds + (seconds > 1 ? ' sec ago' : 'just now')
            }

            return 'Just Now'
        },
        notificationIcon(notification) {
            if (notification.notification === 'will') return 'FileTextIcon'
            if (notification.notification === 'advice')
                return 'MessageSquareIcon'
            if (notification.notification === 'payment') return 'DollarSignIcon'
            if (notification.notification === 'subscription')
                return 'DollarSignIcon'
            if (notification.notification === 'user') return 'UserIcon'
            if (notification.notification === 'email') return 'MailIcon'
        },
        notificationColour(notification) {
            if (notification.notification === 'will') return 'primary'
            if (notification.notification === 'advice') return 'warning'
            if (notification.notification === 'payment') return 'pallet2'
            if (notification.notification === 'subscription') return 'pallet1'
            if (notification.notification === 'user') return 'success'
            if (notification.notification === 'email') return 'pallet4'
        },
        title(notification) {
            if (notification.notification === 'will') return 'New Will'
            if (notification.notification === 'advice')
                return 'New Advice Request'
            if (notification.notification === 'payment')
                return 'Payment Recieved £' + notification.paid_amount / 100
            if (notification.notification === 'subscription')
                return 'New Subscription Created'
            if (notification.notification === 'user')
                return 'New User Account Created'
            if (notification.notification === 'email')
                return 'New Email Received'
        },
        subTitle(notification) {
            if (notification.notification === 'will')
                return notification.user.details.full_name
            if (notification.notification === 'advice') {
                if (notification.user)
                    return notification.user.details.full_name
                return 'Advice from broker'
            }
            if (notification.notification === 'payment')
                return notification.user.details.full_name
            if (notification.notification === 'subscription')
                return notification.user.details.full_name
            if (notification.notification === 'user') return notification.email
            if (notification.notification === 'email')
                return notification.user.full_name
        },
        gotoPage(notification) {
            if (notification.notification === 'will')
                this.$router.push({
                    name: 'will',
                    params: { id: notification.id },
                })
            else if (notification.notification === 'advice') {
                if (notification.user)
                    this.$router.push({
                        name: 'user',
                        params: { id: notification.user.id },
                    })
                else this.$router.push({ name: 'advice' })
            } else if (notification.notification === 'payment')
                this.$router.push({
                    name: 'will',
                    params: { id: notification.id },
                })
            else if (notification.notification === 'subscription')
                this.$router.push({
                    name: 'will',
                    params: { id: notification.id },
                })
            else if (notification.notification === 'user')
                this.$router.push({
                    name: 'user',
                    params: { id: notification.details.user_id },
                })
            else if (notification.notification === 'email')
                this.$router.push({
                    name: 'user',
                    params: { id: notification.user.user_id },
                })
        },
    },
}
</script>
