export default [
    {
        url: '/home',
        name: 'Home',
        slug: 'home',
        icon: 'HomeIcon',
    },
    {
        url: '',
        name: '',
        slug: '',
        icon: '',
        i18n: '',
    },
    {
        url: '/send_single',
        name: 'Send Single',
        slug: 'sendSingle',
        icon: 'ChevronRightIcon',
        i18n: 'Send Single',
    },
    {
        url: '/send_batch',
        name: 'Send Batch',
        slug: 'sendBatch',
        icon: 'ChevronsRightIcon',
        i18n: 'Send Batch',
    },
    {
        url: '/handover',
        name: 'Handover',
        slug: 'handover',
        icon: 'FilePlusIcon',
        i18n: 'Handover',
    },
    {
        url: '',
        name: '',
        slug: '',
        icon: '',
        i18n: '',
    },
    {
        url: '/referral',
        name: 'Referral',
        slug: 'referral',
        icon: 'ThumbsUpIcon',
        i18n: 'Referral',
    },
    {
        url: '/leads',
        name: 'Leads',
        slug: 'leads',
        icon: 'MessageSquareIcon',
        i18n: 'Leads',
    },
    {
        url: '/clients',
        name: 'Clients',
        slug: 'clients',
        icon: 'UsersIcon',
        i18n: 'Clients',
    },
    {
        url: '',
        name: '',
        slug: '',
        icon: '',
        i18n: '',
    },

    {
        url: '/quick_codes',
        name: 'Access Codes',
        slug: 'quickCodes',
        icon: 'CodeIcon',
        i18n: 'Access Codes',
    },
    {
        url: '',
        name: 'Finance',
        slug: 'finance',
        icon: 'DollarSignIcon',
        i18n: 'Finance',
        submenu: [
            {
                url: '/finance/invoices',
                name: 'Invoices',
                slug: 'invoices',
                icon: 'FileIcon',
                i18n: 'Invoices',
            },
            {
                url: '/finance/commissions',
                name: 'Commissions',
                slug: 'commissions',
                icon: 'FileIcon',
                i18n: 'Commissions',
            },
        ],
    },
    {
        url: '',
        name: 'Reports',
        slug: 'reports',
        icon: 'PieChartIcon',
        i18n: 'Reports',
        submenu: [
            {
                url: '/reports/completed_wills',
                name: 'Completed Wills',
                slug: 'completedWills',
                i18n: 'completedWills',
            },
            {
                url: '/reports/send_logs',
                name: 'Send Logs',
                slug: 'sendLogs',
                i18n: 'sendLogs',
            },
            {
                url: '/reports/professional_executors',
                name: 'Professional Executors',
                slug: 'professionalExecutors',
                i18n: 'professionalExecutors',
            },
        ],
    },

    {
        url: '',
        name: '',
        slug: '',
        icon: '',
        i18n: '',
    },
    {
        url: '/settings',
        name: 'Settings',
        slug: 'settings',
        icon: 'SettingsIcon',
        i18n: 'Settings',
    },
]
